import { Component, Provide, Vue, Watch, } from 'vue-property-decorator';
import common from '../../Common/Index.vue'
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import {
  AcceptOrderApi,
  CancelOrderApi,
  ConfirmReceiptApi,
  DeleteOrderApi,
  GetByIdOrderApi,
  GetLogisticsApi,
  ModifyAddressApi,
  RefundOrderApi
} from "@/network/modules/mine";
import { ModifyAddressReq, OrderIdReq } from "@/interface/res/mine";
import { mapGetters } from "vuex";

@Component({
  components: {
    common,
    ElImageViewer
  },
  computed: {
    ...mapGetters(["addressList", 'orderStatuMsg'])
  }
})
export default class OrderDetail extends Vue {
  @Provide() id: Array<number> = []
  @Provide() loading: boolean = false
  @Provide() orderStatus: number = -1
  @Provide() orderStep: number = 0
  @Provide() detail: any = {}
  @Provide() title: string = ''
  @Provide() expirationTime: any = {}
  @Provide() timer: any = null

  @Provide() logistics: any = {}

  @Provide() dialogShow: boolean = false
  @Provide() dialogType: number = 0
  @Provide() dialogLoading: boolean = false

  @Provide() addressShow: boolean = false
  @Provide() addressLoading: boolean = false
  @Provide() addressData: any = {}
  @Provide() addressId: number = -1


  @Provide() orderProcessList: Array<any> = []
  @Provide() orderProcessListTotal: number = -1

  @Provide() showBig: boolean = false;
  @Provide() showBigImgList: Array<any> = [];
  @Provide() showBigImgListIndex: number = 0;


  @Watch('orderStatuMsg', { immediate: false, deep: false })
  private orderChange(): void {
    const _this: any = this;
    if (!_this.$base.isNull(_this.$route.query.i)) {
      _this.id = _this.$decryptBy(_this.$route.query.i)
      _this.GetByIdOrderFn()
      _this.GetLogisticsFn()
    }
  }
  @Watch('orderStatus', { immediate: true, deep: false })
  private iValChange(newVal: number): void {
    const _this: any = this
    console.log(newVal);
    console.log(_this.detail);

    // 步骤条
    if (newVal == 0 || newVal == 1) {
      _this.orderStep = 1
    }
    else if (newVal == 2 || newVal == 7 || newVal == 8) {
      _this.orderStep = 2
    }
    else if (newVal == 3) {
      _this.orderStep = 3
    }
    else if (newVal == 4) {
      _this.orderStep = 4
    }
    else {
      _this.orderStep = 5
    }


    if (newVal == 0) {
      _this.title = '待付款'
      _this.countDown(_this.detail.expirationTime)
    }
    else if (newVal == 1) {
      _this.title = '待付款（分笔支付）'
      _this.countDown(_this.detail.expirationTime, _this.detail.orderStatus)
    }
    else if (newVal == 2 || newVal == 3) {
      _this.title = '待发货'
    }
    else if (newVal == 4) {
      _this.title = '待收货'
    }
    else if (newVal == 5 || newVal == 6) {
      _this.title = '已完成'
    }
    else if (newVal == 7) {
      _this.title = '排单中...'
    }
    else if (newVal == 8) {
      _this.title = '排单延时'
      _this.countDown(_this.detail.expirationTime)
    }
    else if (newVal == 9) {
      _this.title = '退款中...'
    }
    else if (newVal == 10) {
      _this.title = '已退款'
    }
    else if (newVal == 11) {
      _this.title = '退款失败'
    }
    else if (newVal == 12) {
      _this.title = '交易关闭'
    }
  }
  created() {
    const _this: any = this;
    if (!_this.$base.isNull(_this.$route.query.i)) {
      _this.id = _this.$decryptBy(_this.$route.query.i)
      _this.GetByIdOrderFn()
      _this.GetLogisticsFn()
    }

  }

  /**
   * @Author HS
   * @Date 2021/8/12 10:28 上午
   * @Description: 查询物流
   * @Params: null
   * @Return: null
  */
  async GetLogisticsFn() {
    const _this: any = this;
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await GetLogisticsApi(params)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }

    if (_this.$base.isNull(data)) return
    console.log(data)
    _this.logistics = data
  }

  /**
   * @Author HS
   * @Date 2021/8/6 4:44 下午
   * @Description: 产品详情
   * @Params: null
   * @Return: null
  */
  async GetByIdOrderFn() {
    const _this: any = this;
    _this.loading = true;
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await GetByIdOrderApi(params)
    let a = setTimeout(() => { _this.loading = false; clearTimeout(a); }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.detail = data
    _this.orderStatus = _this.detail.orderStatus
    _this.addressData = {
      receiverName: data.receiverName,
      receiverPhone: data.receiverPhone,
      receiverAddress: data.receiverAddress,
    }
  }
  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 定单弹窗
   * @Params: { object ： item - false } [当前数据]
   * @Params: { number ： iType - true } [类型:1-删除 2-退款 3-接受延时 5-取消定单 7-确认收货]
   * @Return: null
   */
  dialogOpenFn(iType: number) {
    const _this: any = this;
    _this.dialogShow = true
    _this.dialogType = iType
  }

  /**
   * @Author HS
   * @Date 2021/8/5 2:16 下午
   * @Description: 弹窗确认回调
   * @Params: null
   * @Return: null
   */
  confirmCallbackFn() {
    const _this: any = this;
    switch (_this.dialogType) {
      case 5:
        _this.CancelOrderFn()
        break;
      case 3:
        _this.AcceptOrderFn()
        break;
      case 2:
        _this.RefundOrderFn()
        break;
      case 7:
        _this.ConfirmReceiptFn()
        break;
      case 1:
        _this.DeleteOrderFn()
        break;
    }
  }

  /**
   * @Author HS
   * @Date 2021/8/12 9:34 上午
   * @Description: 删除定单
   * @Params: null
   * @Return: null
  */
  async DeleteOrderFn() {
    const _this: any = this;
    _this.dialogLoading = true
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await DeleteOrderApi(params)
    let a = setTimeout(() => { _this.dialogLoading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.dialogShow = false
    _this.dialogType = 0
    _this.$message({
      message: '已删除',
      type: 'success',
      duration: 1500,
      onClose: () => {
        _this.$router.go(-1)
      }
    });

  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 确认收货
   * @Params: null
   * @Return: null
   */
  async ConfirmReceiptFn() {
    const _this: any = this;
    _this.dialogLoading = true
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await ConfirmReceiptApi(params)
    let a = setTimeout(() => { _this.dialogLoading = false; clearTimeout(a) }, 500)
    const { code, msg } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }

    _this.orderStatus = 5
    _this.dialogShow = false
    _this.dialogType = 0
  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 接受延时
   * @Params: null
   * @Return: null
   */
  async AcceptOrderFn() {
    const _this: any = this;
    _this.dialogLoading = true
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await AcceptOrderApi(params)
    let a = setTimeout(() => { _this.dialogLoading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }

    _this.orderStatus = 2
    _this.dialogShow = false
    _this.dialogType = 0
  }

  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 定单退款
   * @Params: null
   * @Return: null
   */
  async RefundOrderFn() {
    const _this: any = this;
    _this.dialogLoading = true
    let params: OrderIdReq = {
      orderId: _this.item.orderId
    }
    const res = await RefundOrderApi(params)
    let a = setTimeout(() => { _this.dialogLoading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    _this.$base.isNull(data) ? _this.orderStatus = 11 : _this.orderStatus = 10
    _this.dialogShow = false
    _this.dialogType = 0
  }


  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 取消定单
   * @Params: null
   * @Return: null
   */
  async CancelOrderFn() {
    const _this: any = this;
    _this.dialogLoading = true
    let params: OrderIdReq = {
      orderId: _this.id
    }
    const res = await CancelOrderApi(params)
    let a = setTimeout(() => { _this.dialogLoading = false; clearTimeout(a) }, 500)
    const { code, msg, data } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }
    if (_this.orderStatus == 0) {
      _this.orderStatus = 12
    }
    else if (_this.orderStatus == 1) {
      _this.$base.isNull(data) ? _this.orderStatus = 11 : _this.orderStatus = 12
    }
    _this.dialogShow = false
    _this.dialogType = 0
  }



  /**
   * @Author HS
   * @Date 2021/8/5 11:16 上午
   * @Description: 修改地址
   * @Params: { object ： item - true } [当前数据]
   * @Return: null
   */
  async ModifyAddressFn(item: any) {
    const _this: any = this;
    _this.addressLoading = true
    let params: ModifyAddressReq = {
      orderId: _this.id,
      addressId: item.addressId
    }
    const res = await ModifyAddressApi(params)
    let a = setTimeout(() => { _this.addressLoading = false; clearTimeout(a) }, 500)
    const { code, msg } = res
    if (code != 200) {
      _this.$message.error(msg);
      return
    }

    _this.addressData = {
      receiverName: item.addressName,
      receiverPhone: item.addressPhone,
      receiverAddress: item.provinceName + ' ' + item.cityName + ' ' + item.areaName + ' ' + item.detailedAddress
    }
    _this.addressShow = false
  }


  /**
   * @Author HS
   * @Date 2021/8/6 5:03 下午
   * @Description: 联系客服
   * @Params: null
   * @Return: null
  */
  contactCustomerServiceFn() {
    const _this: any = this;
    _this.$store.commit('SET_SERVICESHOW', true)
    _this.$store.commit('SET_UNREAD', 0);
  }


  /**
   * @Author HS
   * @Date 2021/8/6 2:36 下午
   * @Description: 倒计时
   * @Params: { number ： endTimeStamp - true } [时间戳]
   * @Return: null
  */
  countDown(endTimeStamp: number, orderStatus: number) {
    console.log('%c [orderStatus]: ', 'color: #bf2c9f; background: pink; font-size: 13px;', orderStatus)
    const _this: any = this;
    _this.timer = setTimeout(() => {
      let expirationTime = _this.$base.countDown(endTimeStamp)
      if (expirationTime.end) {
        if (_this.detail.paidPrice > 0) {
          if (orderStatus != 1) {
            _this.orderStatus = 10
          }
        }
        else {
          _this.orderStatus = 12
        }
        clearTimeout(_this.timer)
        return
      }
      _this.countDown(endTimeStamp)
      _this.expirationTime = expirationTime
    }, 1000)
  }

  /**
   * @Author HS
   * @Date 2021/8/10 11:05 上午
   * @Description: 结算
   * @Params: null
   * @Return: null
   */
  settlementFn() {
    const _this: any = this;
    const path = _this.$base.routeLinkSplice(_this.$IndexConfig.pay.path, 'i=' + _this.id)
    _this.$base.goRouter(path)
  }

  /**
   * @Author HS
   * @Date 2021/7/29 11:19 上午
   * @Description: 图片放大
   * @Params: { object ：item - true } [当前数据]
   * @Params: { number ：index - true } [下标]
   * @Return: null
   */
  imgClick(item: any, index: number) {
    const _this: any = this;
    _this.showBigImgList = JSON.parse(item)
    _this.showBigImgListIndex = index
    _this.showBig = true;
  }


  /**
   * @Author HS
   * @Date 2021/7/26 5:04 下午
   * @Description: 页面销毁
   * @Params: null
   * @Return: null
   */
  destroyed() {
    const _this: any = this;
    clearTimeout(_this.timer)
  }

  /**
   * @Author HS
   * @Date 2021/8/5 2:51 下午
   * @Description: 跳转页面
   * @Params: { string ： iPath - true } [路径]
   * @Params: { string ： id - false } [id]
   * @Return: null
   */
  goRouter(iPath: string, id: string) {
    const _this: any = this;
    let link = ''
    if (_this.$base.isNull(id)) {
      link = _this.$base.routeLinkSplice(_this.$MineConfig[iPath].path, "i=" + _this.id.toString())
      _this.$base.goRouter(link)
      return
    }
    link = _this.$base.routeLinkSplice(_this.$MineConfig[iPath].path, "i=" + id)
    _this.$base.goRouter(link)
  }


  /**
   * @Author HS
   * @Date 2021/7/8 11:46 上午
   * @Description: 跳转到详情
   * @Params: { string ： id - true } [id]
   * @Params: { string ： iType - true } [定单类型: 0-小家碧玉 1-储绣家居 2-高级定制]
   * @Return: null
   */
  goDetails(id: string, iType: string) {
    const _this: any = this;
    if (iType == '2') return;
    let link = ''
    if (iType == '0') {
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path, `type=${iType}&i=` + id)
    }
    if (iType == '1') {
      link = _this.$base.routeLinkSplice(_this.$IndexConfig.embroideryHomeDetail.path, `type=${iType}&i=` + id)
    }
    _this.$base.goRouter(link)
  }
}
